import {Inject, Injectable} from "@angular/core";
import {RequestsService} from "../../shared/services/requests.service";
import {ExpertListModel} from "../../shared/models/admin-request-data";
import {
    defaultLanguage, getExpertiseName, getLangsName,
    MAX_EXPERTS_COUNT_PER_PAGE,
    MAX_TOPICS_COUNT_PER_PAGE, ProfileColor,
    TopicsColor
} from "./experts.config";
import {MenuOption} from "../../shared/models/menu-option";
import {NotificationsService} from "../../shared/services/notifications.service";


@Injectable()
export class ExpertsHelperService {
    private _totalPosts: number = 0;
    localTopics: MenuOption[] = [];
    startSlice = 0;
    endSlice = 2;
    showAll: boolean = false;


    constructor(private adminRequestsService: RequestsService,
                private notifier: NotificationsService,) {
    }

    fetchExperts = async (lang: string[], fullName: string | undefined, page: number, expertise: string[], withPage: boolean, sb : string = "created", sd: string = 'down') => {
        try {
            const json: ExpertListModel = {
                fullName: fullName,
                count: MAX_EXPERTS_COUNT_PER_PAGE,
                page: page > 0 ? page : 0,
                sb: sb,
                sd: sd,
            }

            if(withPage) {
                json['hasProfilePage'] = withPage ? "true" : "false"
            }

            if(lang !== undefined && lang.length > 0) {
                json['lang'] = lang;
            }


            if(expertise !== undefined && expertise.length > 0) {
                json['expertise'] = expertise;
            }

            const data = await this.adminRequestsService.getExperts(json);

            if (data && data.result) {
                this._totalPosts = data.metadata.total;
                return  data;
            } else {
                return null;
            }
        } catch (e) {
            this.notifier.error("Can't fetch experts, Please try again");
            throw(e);
        }

    }


    formatExpertDetails = (data: any) => {
        data.forEach(expert => {
            this.setDefaultProfileImageUrls(expert);
            this.formatExpertExpertise(expert);
            this.formatExpertLangs(expert);
            this.formatExpertProfileColor(expert);
            this.formatExpertDescription(expert);
        });
    }

    private setDefaultProfileImageUrls = (expert: any) => {
        if (!expert.profileImageUrl) {
            expert.profileImageUrl = "img/user_placeholder_icon.svg";
        }
    }

    private formatExpertExpertise = (expert : any) => {
        const topics : MenuOption[] = [];
        for(let i = 0; i < expert.expertise?.length; i++) {
            if(expert.expertise[i]) {
               topics[i] = new MenuOption(expert.expertise[i], getExpertiseName(expert.expertise[i]), this.findTopicColor(expert.expertise[i]));
            }
        }
        expert.expertise = topics;
        expert.moreExpertise = '+' + (topics.length - this.endSlice);
    }


    private formatExpertLangs = (expert : any) => {
        const langs : MenuOption[] = [];
        for(let i = 0; i < expert.lang?.length; i++) {
            if(expert.lang[i]) {
                langs[i] = new MenuOption(expert.lang[i], getLangsName(expert.lang[i]), this.findTopicColor(expert.lang[i]));
            }
        }
        expert.lang = langs;
        expert.moreLangs = '+' + (langs.length - this.endSlice);
    }

    private formatExpertProfileColor = (expert: any) => {
        expert['profileColor'] = ProfileColor[this.getRandomInt()];
    }

    private formatExpertDescription = (expert: any) => {
        expert.description = expert.bio ? expert.bio : "";
    }


    getRandomInt() {
        const max = Math.floor(1000);
        return Math.floor(Math.random() * max) % ProfileColor.length;
    }

    getTotalExperts = () => {
        return this._totalPosts;
    }

    getLanguageOptions = () => {
        return [
            defaultLanguage,
            new MenuOption("en", "English"),
            new MenuOption("fr", "French"),
            new MenuOption("pt", "Portuguese"),
            new MenuOption("es", "Spanish"),
            new MenuOption("ru", "Russian"),
            new MenuOption("he", "Hebrew")
        ];
    }

    getExpertises =  () => {
        return this.formatTopicsData([
            new MenuOption("onCameraSkills", "On Camera Skills"),
            new MenuOption("videoMarketing", "Video Marketing"),
            new MenuOption("realEstateMarketing", "Real Estate Marketing"),
            new MenuOption("videoEditing", "Video Editing"),
            new MenuOption("smallBusinessCouching", "Small Business Couching"),
        ]);
    }


    formatTopicsData = (topicsData: any[]) => {
        const topics: MenuOption[] = [];
        for(let i = 0; i < topicsData.length; i++) {
            topics.push(new MenuOption(topicsData[i].value, topicsData[i].name,  TopicsColor[i % 4]));
        }
        this.localTopics = topics;
        return topics;
    }


    findTopicColor = (id : string) => {
        const topic =  this.localTopics.find(t => t.value === id);
        if(topic) {
            return topic.style;
        }else{
            return TopicsColor[0];
        }
    }

}
